







import Vue from 'vue';
import Component from 'vue-class-component';

import { Chart } from 'highcharts-vue';

// этот компонент работает только при динамическом импорте, иначе крашится в режиме ssr
@Component({
  name: 'AppSemiCircleChart',
  props: {
    data: Array,
    titleText: String,
    showValue: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    highcharts: Chart,
  },
})
export default class AppSemiCircleChart extends Vue {
  // props
  data: any;
  titleText: string;
  showValue: boolean;

  get pointFormat() {
    if (this.showValue) {
      return '<b>{point.percentage:.1f}%</b><br>{point.y} ₽';
    }
    return '<b>{point.percentage:.1f}%</b>';
  }

  initOptions() {
    return {
      chart: {
        plotBorderWidth: 0,
        plotShadow: false,
        height: 200,
        spacing: [ 0, 0, 0, 0 ],
      },
      title: {
        useHTML: true,
        // widthAdjust: 0,
        text: this.titleText,
        align: 'center',
        verticalAlign: 'middle',
        y: 35,
      },
      tooltip: {
        pointFormat: this.pointFormat,
        // pointFormat: 'Количество штрафов: <b>{point.value}</b>',
        borderRadius: 20,
        borderWidth: 0,
        shadow: false,
      },
      accessibility: {
        point: {
          valueSuffix: '%',
        },
      },
      plotOptions: {
        pie: {
          dataLabels: {
            enabled: false,
            distance: -50,
            style: {
              fontWeight: 'bold',
              color: 'white',
            },
          },
          startAngle: -90,
          endAngle: 90,
          center: [ '50%', '75%' ],
          size: '110%',
        },
      },
      series: [ {
        type: 'pie',
        name: '',
        innerSize: '70%',
        data: this.data,
        colors: [
          '#9CA3AF',
          '#336FEE',
          '#FE203A',
          '#49C236',
          '#FFE146',
          '#24BA96',
          '#F0A900',
        ],
        // data: [
        //   [ 'Chrome', 73.86 ],
        //   [ 'Edge', 11.97 ],
        //   [ 'Firefox', 5.52 ],
        //   [ 'Safari', 2.98 ],
        //   [ 'Internet Explorer', 1.90 ],
        //   [ 'Other', 3.77 ],
        // ],
      } ],
    };
  }
}
